import React, { Suspense, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import reportWebVitals from './reportWebVitals';
import {
  defaultGlobalState,
  GlobalStateContext,
} from './constants/defaultValues';
// import Context from './Context';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const Main = () => {
  const [globalState, setGlobalState] = useState(defaultGlobalState);
  const _state = { globalState, setGlobalState };
  global.globalStateContext = _state;
  return (
    <GlobalStateContext.Provider value={_state}>
      <Provider store={configureStore()}>
        <Suspense fallback={<div className="loading" />}>
          <App />
        </Suspense>
      </Provider>
    </GlobalStateContext.Provider>

  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
