import firebase from 'firebase/app';
import 'firebase/auth';

import { firebaseConfig } from '../constants/defaultValues';

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export { auth };
