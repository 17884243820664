import axios from 'axios';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  UserRole,
} from '../constants/defaultValues';
import { ruleAllowForUserEdit } from '../constants/userValues';

const localStorageUserKey = 'sp_current_user';

export const getRandomId = () => {
  const randomId = String(new Date().getTime()) + parseInt(Math.random() * 1000);
  return Number(randomId);
};

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error,
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error,
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language = localStorage.getItem('currentLanguage')
      && localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage'),
      ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error,
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error,
    );
  }
};

export const getCurrentUser = () => {
  let user;
  try {
    user = localStorage.getItem(localStorageUserKey) != null
      && localStorage.getItem(localStorageUserKey)
      ? JSON.parse(localStorage.getItem(localStorageUserKey))
      : undefined;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = undefined;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem(localStorageUserKey, JSON.stringify(user));
    } else {
      localStorage.removeItem(localStorageUserKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const retry = (fn, retriesLeft = 5, interval = 1000) => new Promise((resolve, reject) => {
  fn()
    .then(resolve)
    .catch((error) => {
      setTimeout(() => {
        if (retriesLeft === 1) {
          // reject('maximum retries exceeded');
          reject(error);
          return;
        }

        // Passing on "reject" is the important part
        window.location.reload();
        retry(fn, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
});

export const validateFromDateToDate = (fromDate, toDate) => {
  if (fromDate && toDate) {
    if (fromDate.length !== 10 || toDate.length !== 10) {
      return false;
    }
    const from = new Date(fromDate);
    const to = new Date(toDate);
    if (from > to) {
      return false;
    }
  }
  return true;
};

export const truncateStr = (str, length) => {
  if (str.length > length) {
    return `${str.substring(0, length)}...`;
  }
  return str;
};

export const numberFormat = (number, decimalLimit = 2) => parseFloat(number).toFixed(decimalLimit);

export const validateNumber = (value) => {
  const re = /^[0-9\b]+$/;
  if (value === '' || re.test(value)) {
    return true;
  }
  return false;
};

export const axiosGet = (url, payload) => axios.get(url, payload);

export const axiosPost = (url, payload, headers = {}) => axios.post(url, payload, { headers });

export const axiosPut = (url, payload) => axios.put(url, payload);

export const axiosDelete = (url, payload) => axios.delete(url, payload);

export const updateUserInfo = (user) => {
  if (!user || typeof user !== 'object') {
    return false;
  }
  const userData = user;
  const _userObject = {
    uid: userData.userId,
    id: userData.userId,
    title: `${userData.firstName} ${userData.lastName}`,
    img: '/assets/img/profiles/l-1.jpg',
    date: 'Last seen today 15:24',
    role: ruleAllowForUserEdit.includes(userData.parentRoleId)
      ? UserRole.Admin
      : UserRole.Editor,
    ...userData,
  };
  setCurrentUser(_userObject);
  return true;
};

export const scrollableTable = (
  headerClass = 'custom-table-head',
  bodyClass = 'custom-table-body',
) => {
  const tableHead = document.querySelector(`.${headerClass}`);
  const tableBody = document.querySelector(`.${bodyClass}`);
  if (tableBody) {
    tableBody.addEventListener('scroll', () => {
      tableHead.scrollLeft = tableBody.scrollLeft;
    });
  }
  if (tableHead) {
    tableHead.addEventListener('scroll', () => {
      tableBody.scrollLeft = tableHead.scrollLeft;
    });
  }
};

export const isEmpty = (val) => !!(!val || val.length <= 0 || val == '');

export const slugify = (str) => (!isEmpty(str)
  ? str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '_')
    .replace(/^-+|-+$/g, '')
  : str);

export const titleize = (slug) => {
  if (!slug) {
    return '';
  }

  slug = slug.replace(/_/g, ' ');

  const words = slug.split(' ');
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
};

export const getSampleTypeDescription = (sampleType, sampleTypeMapper) => {
  if (!isEmpty(sampleType) && !isEmpty(sampleTypeMapper)) {
    if (sampleType in sampleTypeMapper) {
      return sampleTypeMapper[sampleType];
    }
    return '';
  }
  return '';
};

export const getQcStatusOrDefault = (slug) => {
  if (!slug) {
    return 'Pending';
  }
  slug = slug.replace(/_/g, ' ');

  const words = slug.split(' ');
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
};

export const unixTimestampToHumanDate = (unixTimestamp) => {
  if (!unixTimestamp) {
    return '';
  }
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const date = new Date(unixTimestamp);

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);

  return `${day} ${month}'${year}`;
};

export const unixTimestampToCustomFormat = (unixTimestamp) => {
  if (!unixTimestamp) {
    return '';
  }
  const date = new Date(unixTimestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes} GMT`;
};

export const getValueByCode = (data, code) => {
  if (!data || !code) {
    return '';
  }
  const field = data.find((item) => item.code === code);
  return field ? field.value : undefined;
};

export const getValueOrDefault = (value) => {
  if (
    value == undefined
    || value == null
    || value == 'undefined'
    || value == 'null'
  ) {
    return '';
  }
  return value;
};


export const productStatusList = {
  "3": "New",
  "6": "Buying",
  "12": "Draft PO",
  "13": "PO",
  "17": "Rebuy",
  "19": "Resource"
};
